
import { Component, Watch } from 'vue-property-decorator'
import Emitter from '@/utils/emitter'
import { isWechat } from '@/utils/utils'
import { formatDate } from '@/utils/date'
import { stringify } from 'querystring'
const baseUrl = process.env.VUE_APP_API_BASE_URL
@Component({
	filters: {
		formatDate(value: number) {
			return value ? formatDate('yyyy-MM-dd h:i:s', value) : '-'
		}
	}
})
export default class extends Emitter {
	private isWechat = false

	private pageConfig = {
		total: 0,
		size: 20,
		current_page: 1
	}
	private selectedRowCodes = []
	@Watch('pageConfig.current_page')
	onPagination() {
		this.search()
	}
	private isLoading = false
	private searchForm = {
		boxCode: '',
		feederCode: '',
		feederCompany: '',
		channelId: [],
		dateTime: []
	}
	private pickerOptions = {
		shortcuts: [
			{
				text: '最近一天',
				onClick(picker: any) {
					const end = new Date()
					const start = new Date()
					start.setTime(start.getTime() - 3600 * 1000 * 24 * 1)
					picker.$emit('pick', [start, end])
				}
			},
			{
				text: '最近一周',
				onClick(picker: any) {
					const end = new Date()
					const start = new Date()
					start.setTime(start.getTime() - 3600 * 1000 * 24 * 7)
					picker.$emit('pick', [start, end])
				}
			},
			{
				text: '最近半个月',
				onClick(picker: any) {
					const end = new Date()
					const start = new Date()
					start.setTime(start.getTime() - 3600 * 1000 * 24 * 15)
					picker.$emit('pick', [start, end])
				}
			},
			{
				text: '最近一个月',
				onClick(picker: any) {
					const end = new Date()
					const start = new Date()
					start.setTime(start.getTime() - 3600 * 1000 * 24 * 30)
					picker.$emit('pick', [start, end])
				}
			},
			{
				text: '最近三个月',
				onClick(picker: any) {
					const end = new Date()
					const start = new Date()
					start.setTime(start.getTime() - 3600 * 1000 * 24 * 90)
					picker.$emit('pick', [start, end])
				}
			}
		]
	}
	private form = {
		index: undefined,
		row: {
			code: '',
			id: ''
		},
		visible: false,
		totalWeight: undefined,
		tareWeight: undefined,
		channelId: undefined
	}

	get printLabels() {
		const baseUrl = process.env.VUE_APP_API_BASE_URL
		return `${baseUrl}v1/jobline/generate/package-bar-code/${this.selectedRowCodes.join(
			','
		)}?title=箱`
	}
	private channels: any = []
	private list: any = []
	created() {
		this.isWechat = isWechat()
		const {
			box_code = '',
			size,
			current_page,
			channelId = undefined
		}: any = this.$route.query
		if (box_code) {
			this.searchForm.boxCode = box_code
		}
		this.searchForm.channelId = channelId
			? channelId.split(',').map((v: any) => Number(v))
			: []
		this.pageConfig.size = Number(size || 20)
		this.pageConfig.current_page = Number(current_page || 1)
		this.search()
		this.getChannels()
	}
	async getChannels() {
		const { data } = await this.$axios.get('v1/jobline/channels', {})
		this.channels = data
	}
	async initJSSdk() {
		if (!this.isWechat) return false
		const url = location.href.split('#')[0]
		const { data } = await this.$axios.post('v1/jobline/js-sdk-config', {
			url
		})
		this.$wx.config({
			debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
			...data,
			jsApiList: ['scanQRCode'] // 必填，需要使用的JS接口列表
		})
	}
	handleSelectionChange(rows: any) {
		this.selectedRowCodes = rows.map((row: any) => row.code)
	}
	async scanBarcode() {
		if (!this.$wx) return false
		await this.initJSSdk()
		this.$wx.ready(() => {
			this.$wx.scanQRCode({
				needResult: 1,
				scanType: ['barCode'],
				success: (res: any) => {
					const result = res.resultStr.split(',')
					this.searchForm.boxCode = result[result.length - 1]
					this.search()
				}
			})
		})
	}
	async voidItem({ row, $index }: any) {
		const isConfirm = await this.$confirm('确认废弃？', '提示', {
			confirmButtonText: '确认',
			cancelButtonText: '取消'
		}).catch(() => false)
		if (!isConfirm) return false
		const { code } = row
		const data = await this.$axios.post('v1/jobline/box/destroy', {
			box_code: code
		})
		if (data) {
			this.list[$index].status = 0
		}
	}
	async editItem({ row, $index }: any) {
		this.form.visible = true
		this.form.row = row
		this.form.index = $index
		this.form.totalWeight = row.total_weight
		this.form.tareWeight = row.tare_weight
		this.form.channelId = row.channel_id
	}
	async downloadPackage(code: string) {
		window.open(`${baseUrl}v1/jobline/box/export/${code}`)
	}
	printLabel(code: string) {
		const baseUrl = process.env.VUE_APP_API_BASE_URL
		return `${baseUrl}v1/jobline/generate/package-bar-code/${code}?title=箱`
	}
	async confirmStockOut() {
		const isConfirm = await this.$confirm('是否确认？', '提示', {
			confirmButtonText: '确认',
			cancelButtonText: '取消'
		}).catch(() => false)
		if (!isConfirm) return
		const postData = {}
		const { data } = await this.$axios.post('/', postData).catch(e => {
			this.$message.error(e.message)
			return { data: null }
		})
		if (!data) return false
	}
	resetSearch() {
		this.searchForm.boxCode = ''
		this.searchForm.feederCode = ''
		this.searchForm.feederCompany = ''
		this.searchForm.channelId = []
		this.search(true)
	}
	handelSizeChange(val: number) {
		this.pageConfig.size = val
		this.search(true)
	}
	async exportBoxData() {
		const exportDatas = this.list
		const boxIds = []

		for (let index = 0; index < exportDatas.length; index++) {
			const item = exportDatas[index]
			boxIds.push(item.id)
		}

		const params = {
			feeder_code: this.searchForm.feederCode,
			box_ids: boxIds.join(',')
		}

		window.open(
			`${baseUrl}v1/jobline/box/export/data-info?${stringify(params)}`
		)
	}
	async search(reset?: boolean) {
		if (reset) {
			this.pageConfig.current_page = 1
		}
		if (this.isLoading) {
			return
		}
		const {
			boxCode,
			channelId = undefined,
			dateTime,
			feederCode,
			feederCompany
		} = this.searchForm
		const { size, current_page } = this.pageConfig
		this.isLoading = true
		const params = {
			page_size: size,
			page_index: current_page,
			box_code: boxCode,
			feeder_code: feederCode,
			feeder_company: feederCompany,
			channelId: channelId?.join(','),
			start_time: 0,
			end_time: 0
		}

		if (dateTime) {
			const [start, end] = dateTime
			params.start_time = Math.round(new Date(start).getTime() / 1000)
			params.end_time = Math.round(new Date(end).getTime() / 1000)
		}
		const { data } = await this.$axios
			.get('/v1/jobline/box', {
				params: params
			})
			.catch(() => {
				return {
					data: null
				}
			})
			.finally(() => (this.isLoading = false))

		this.$router
			.push({
				query: {
					box_code: boxCode,
					channelId: channelId?.join(','),
					size: String(size),
					current_page: String(current_page)
				}
			})
			.catch(err => err)
		if (!data) {
			return
		}
		this.list = data.list
		this.pageConfig.total = data.page.total
	}

	async submitForm() {
		const {
			totalWeight = 0,
			tareWeight = 0,
			channelId = 0,
			row: { id }
		} = this.form
		this.isLoading = true
		const { data } = await this.$axios
			.post(`/v1/jobline/box/update-weight/${id}`, {
				total_weight: totalWeight ? Math.round(totalWeight * 1000) : undefined,
				tare_weight: tareWeight ? Math.round(tareWeight * 1000) : undefined,
				channel_id: channelId || undefined
			})
			.catch(() => {
				return {
					data: null
				}
			})
			.finally(() => (this.isLoading = false))
		if (!data) return
		this.form.visible = false
		this.search()
	}
}
